.contacts-info-mobile {
  width: 90%;
  margin: 0 auto;
}

.contacts-info-desktop {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 1;
}

.contacts-info-background-wrapper {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contacts-info-background-wrapper-mobile {
  width: 90%;
  padding: 4vw 4vw 0 4vw;
  border-radius: 5vw;
}

.contacts-info-background-wrapper-desktop {
  width: 50%;
  padding: 2vw 2vw 0 2vw;
  border-radius: 1.5vw;
}

.contacs-info-wrapper {
  height: auto;
}

.contacts-title-mobile {
  font: normal normal 300 3.84vw/5.57vw Comfortaa;
  text-align: center;
  margin-bottom: 3vw;
  color: #666666;
}

.contacts-title-desktop {
  text-align: left;
  font: normal normal 300 0.84vw/1.57vw Comfortaa;
  margin-bottom: 1vw;
  color: #666666;
}

.contacs-email-container-mobile {
  display: flex;
  text-align: left;
  align-items: center;
  margin-bottom: 4vw;
  gap: 1vw;
}

.contacs-email-container-desktop {
  display: flex;
  gap: 0.5vw;
  margin-bottom: 0vw;
  align-items: flex-start;
  margin-bottom: 0.4vw;
}

.contacs-email-container-mobile p {
  font: normal normal 300 3vw/5vw Comfortaa;
  margin-left: 1.4vw;
  color: #666;
}

.contacs-email-container-desktop p {
  font: normal normal 300 0.84vw/1.57vw Comfortaa;
  text-align: left;
  color: #666;
}

.contacs-email-container-mobile p a {
  color: #b171b3;
  font: normal normal bold 3vw/2.25vw Comfortaa;
}

.contacs-email-container-desktop p a {
  color: #b171b3;
  font: normal normal bold 0.84vw/1.25vw Comfortaa;
}

.contacts-mail {
  text-transform: lowercase;
}

.contacts-icons-wrapper-desktop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4vw;
}

.constacts-icons-container-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.5vw;
  margin-top: 4vw;
  margin-bottom: 5.5vw;
}

.constacts-icons-container-desktop {
  display: flex;
  align-items: center;
  margin-top: 1vw;
  gap: 0.6vw;
}

.contacts-question-image-container-desktop {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.contacts-icons-wrapper img {
  max-width: 100%;
  height: auto;
}

.contacs-email-image-desktop {
  margin-top: 0.3vw;
}